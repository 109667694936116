import React from 'react'
import PropTypes from 'prop-types'

const s = require('./symbol_counted_sentence.module.scss')

const SymbolCountedSentence = ({
  sentence,
  showCount,
  showCountToppers,
  highCount,
  countOnly,
  left,
}) =>
  countOnly ? (
    <span>{sentence.length}</span>
  ) : (
    <div className={s.container}>
      <div className={`${s.sentenceContainer} ${left ? s.left : ''}`}>
        {sentence.split('').map((char, i) => (
          <div key={`char-${i}-${char}`} className={s.char}>
            {char === ' ' ? '\u00a0' : char}
            {showCountToppers && (
              <span className={`${s.charCount} ${highCount ? s.high : ''}`}>
                {i + 1}
              </span>
            )}
          </div>
        ))}
      </div>
      {showCount && <div className={s.total}>{sentence.length} characters</div>}
    </div>
  )

SymbolCountedSentence.propTypes = {
  sentence: PropTypes.string.isRequired,
  showCount: PropTypes.bool,
  showCountToppers: PropTypes.bool,
  highCount: PropTypes.bool,
  countOnly: PropTypes.bool,
  left: PropTypes.bool,
}

SymbolCountedSentence.defaultProps = {
  showCount: true,
  showCountToppers: true,
  highCount: false,
  countOnly: false,
  left: false,
}

export default SymbolCountedSentence
