export function runLengthEncode(s) {
  let out = ''
  let count = 0
  let current = null
  for (let i = 0; i < s.length; i++) {
    const c = s.charAt(i)
    if (c === current || current === null) {
      count++
      current = c
    } else {
      out += `${count}${current}`
      count = 1
      current = c
    }
  }
  if (count > 0) {
    out += `${count}${current}`
  }
  return out
}
