import React from 'react'
import PropTypes from 'prop-types'

const s = require('./pixel_art.module.scss')

class PixelArt extends React.Component {
  render() {
    const { data, onCellInteract } = this.props
    return (
      <div className={s.container}>
        {data.map((row, ri) => (
          <div key={`row-${ri}`} className={s.row}>
            {row.map((cell, ci) => {
              const backgroundColor = {
                G: '#56e256',
                B: '#03a9f4',
                Y: 'yellow',
              }[cell]
              return (
                <div
                  key={`cell-${ci}`}
                  onClick={onCellInteract ? () => onCellInteract(ri, ci) : null}
                  className={s.cell}
                  style={{ backgroundColor }}
                />
              )
            })}
          </div>
        ))}
      </div>
    )
  }
}

PixelArt.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  onCellInteract: PropTypes.func,
}

export default PixelArt
