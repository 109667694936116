import React from 'react'
import PropTypes from 'prop-types'
import flatten from 'lodash.flatten'

import PixelArt from './pixel_art'
import SymbolCountedSentence from './symbol_counted_sentence'

import { runLengthEncode } from '../../../utils/compression'

class InteractiveRLEPixelArt extends React.Component {
  constructor(props) {
    super(props)
    this.state = { data: props.initialData }
  }

  cycleCell(rowI, cellI) {
    const dataCopy = JSON.parse(JSON.stringify(this.state.data))
    const cell = dataCopy[rowI][cellI]
    const cycle = ['G', 'B', 'Y']
    const next = cycle[(cycle.indexOf(cell) + 1) % cycle.length]
    dataCopy[rowI].splice(cellI, 1, next)
    this.setState({ data: dataCopy })
  }

  render() {
    const { data } = this.state
    const { noToppers, noFullData, editable } = this.props
    return (
      <div>
        <PixelArt
          data={data}
          onCellInteract={editable ? this.cycleCell.bind(this) : null}
        />
        <SymbolCountedSentence
          sentence={runLengthEncode(flatten(data).join(''))}
          left
          showCountToppers={!noToppers}
        />
        {noFullData || (
          <SymbolCountedSentence
            sentence={flatten(data).join('')}
            left
            showCountToppers={false}
          />
        )}
      </div>
    )
  }
}

InteractiveRLEPixelArt.propTypes = {
  initialData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  noToppers: PropTypes.bool,
  noFullData: PropTypes.bool,
  editable: PropTypes.bool,
}

InteractiveRLEPixelArt.defaultProps = {
  noToppers: false,
  noFullData: false,
  editable: true,
}

export default InteractiveRLEPixelArt
