import React from 'react'
import PropTypes from 'prop-types'

import s from './jpeg_multi_compressor.module.scss'

class JPEGMulticompressor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      src: props.initialSrc,
      loaded: false,
      quality: props.initialQuality,
    }
    this.imageElement = null
  }

  componentDidMount() {
    if (this.imageElement && this.imageElement.complete) {
      this.onImageLoad()
    }
  }

  onImageLoad() {
    this.setState({ loaded: true })
  }

  onBetterClick() {
    this.setState({
      quality: Math.min(100, Math.max(0, this.state.quality + 10)),
    })
  }

  onWorseClick() {
    this.setState({
      quality: Math.min(100, Math.max(0, this.state.quality - 10)),
    })
  }

  render() {
    const { src, loaded, quality } = this.state
    let dataUrl = null
    if (loaded) {
      const img = this.imageElement
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0, img.width, img.height)
      dataUrl = canvas.toDataURL(
        'image/jpeg',
        Math.min(100, Math.max(1, quality)) / 100
      )
    }
    return (
      <div className={s.container}>
        <img
          alt={this.props.altText}
          style={{ position: 'absolute', opacity: 0, zIndex: -100 }}
          src={src}
          ref={img => (this.imageElement = img)}
          onLoad={this.onImageLoad.bind(this)}
        />
        {dataUrl && (
          <img alt={this.props.altText} className={s.img} src={dataUrl} />
        )}
        {dataUrl && (
          <div className={s.buttons}>
            <button
              className={`${s.button} ${s.buttonWorse}`}
              onClick={this.onWorseClick.bind(this)}
            >
              Worse
            </button>
            <button
              className={`${s.button} ${s.buttonBetter}`}
              onClick={this.onBetterClick.bind(this)}
            >
              Better
            </button>
          </div>
        )}
        {dataUrl && (
          <div className={s.qualityIndicator}>Quality: {quality}</div>
        )}
      </div>
    )
  }
}

JPEGMulticompressor.propTypes = {
  initialSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  initialQuality: PropTypes.number,
}

JPEGMulticompressor.defaultProps = {
  initialQuality: 20,
}

export default JPEGMulticompressor
